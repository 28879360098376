import type { SVGProps } from 'react'

const TalismanHand = (props: SVGProps<SVGSVGElement>) => (
  <svg width="374" height="372" viewBox="0 0 374 372" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M292.926 208.264C296.19 215.548 305.981 218.093 311.626 212.449L325.161 198.913C335.472 188.603 352.188 188.603 362.498 198.913L366.164 202.579C376.474 212.889 376.474 229.605 366.164 239.916L275.776 330.303C254.502 355.559 222.644 371.606 187.038 371.606C149.917 371.606 116.87 354.166 95.6432 327.032L8.54089 239.93C-1.76929 229.62 -1.76927 212.904 8.54091 202.594L12.2068 198.928C22.517 188.617 39.2331 188.617 49.5433 198.928L62.6452 212.03C68.2751 217.659 78.0366 215.143 81.3179 207.889C81.4254 207.651 81.5337 207.414 81.6428 207.177C82.3009 205.748 82.6653 204.198 82.6653 202.625L82.6652 67.4808C82.6652 52.9 94.4853 41.0799 109.066 41.0799L114.25 41.0799C128.831 41.0799 140.651 52.9 140.651 67.4808L140.651 137.087C140.651 142.853 146.558 146.767 152.056 145.03V145.03C155.536 143.93 158.05 140.762 158.05 137.113L158.05 26.8872C158.05 12.3064 169.87 0.48629 184.451 0.486286L189.635 0.486285C204.216 0.486281 216.036 12.3063 216.036 26.8872L216.036 137.119C216.036 140.767 218.549 143.933 222.027 145.033V145.033C227.523 146.77 233.428 142.857 233.428 137.094L233.428 67.4808C233.428 52.9 245.248 41.0799 259.828 41.0799L265.013 41.0799C279.594 41.0799 291.414 52.9 291.414 67.4808L291.414 202.634C291.414 204.207 291.778 205.756 292.436 207.185C292.601 207.543 292.764 207.903 292.926 208.264Z"
      fill="#003A4D"
    />
    <path
      d="M279.824 267.224C279.824 267.224 238.286 325.21 187.046 325.21C135.807 325.21 94.2686 267.224 94.2686 267.224C94.2686 267.224 135.807 209.238 187.046 209.238C238.286 209.238 279.824 267.224 279.824 267.224Z"
      fill="#FD8FFF"
    />
    <path
      d="M231.785 267.221C231.785 291.929 211.755 311.96 187.046 311.96C162.338 311.96 142.307 291.929 142.307 267.221C142.307 242.512 162.338 222.482 187.046 222.482C211.755 222.482 231.785 242.512 231.785 267.221Z"
      stroke="#003A4C"
      strokeWidth="3.30011"
    />
    <path
      d="M214.389 267.224C214.389 282.325 202.147 294.567 187.046 294.567C171.945 294.567 159.703 282.325 159.703 267.224C159.703 252.122 171.945 239.881 187.046 239.881C202.147 239.881 214.389 252.122 214.389 267.224Z"
      stroke="#003A4C"
      strokeWidth="3.30011"
    />
    <path
      d="M249.178 267.226C249.178 301.542 221.36 329.361 187.044 329.361C152.727 329.361 124.909 301.542 124.909 267.226C124.909 232.91 152.727 205.091 187.043 205.091C221.36 205.091 249.178 232.91 249.178 267.226Z"
      stroke="#003A4C"
      strokeWidth="3.30011"
    />
    <path
      d="M266.574 267.224C266.574 311.147 230.967 346.754 187.044 346.754C143.12 346.754 107.513 311.147 107.513 267.224C107.513 223.3 143.12 187.693 187.044 187.693C230.967 187.693 266.574 223.3 266.574 267.224Z"
      stroke="#003A4C"
      strokeWidth="3.30011"
    />
    <path
      d="M196.991 267.226C196.991 272.72 192.537 277.173 187.044 277.173C181.55 277.173 177.096 272.72 177.096 267.226C177.096 261.732 181.55 257.279 187.044 257.279C192.537 257.279 196.991 261.732 196.991 267.226Z"
      fill="#09FFC4"
      stroke="#003A4C"
      strokeWidth="3.30011"
    />
    <path
      d="M196.991 267.226C196.991 272.72 192.537 277.173 187.044 277.173C181.55 277.173 177.096 272.72 177.096 267.226C177.096 261.732 181.55 257.279 187.044 257.279C192.537 257.279 196.991 261.732 196.991 267.226Z"
      fill="#09FFC4"
      stroke="#003A4C"
      strokeWidth="3.30011"
    />
    <path
      d="M97.4549 268.698C96.9918 268.104 96.613 267.607 96.3216 267.22C96.613 266.834 96.9918 266.337 97.4549 265.743C98.6757 264.177 100.481 261.934 102.808 259.239C107.465 253.847 114.202 246.659 122.521 239.476C139.21 225.066 162.024 210.884 187.046 210.884C212.069 210.884 234.882 225.066 251.572 239.476C259.891 246.659 266.628 253.847 271.284 259.239C273.612 261.933 275.417 264.177 276.638 265.743C277.101 266.337 277.48 266.834 277.771 267.22C277.48 267.607 277.101 268.104 276.638 268.698C275.417 270.264 273.612 272.507 271.284 275.202C266.628 280.594 259.891 287.782 251.572 294.965C234.882 309.375 212.069 323.557 187.046 323.557C162.024 323.557 139.21 309.375 122.521 294.965C114.202 287.782 107.465 280.594 102.808 275.202C100.481 272.507 98.6758 270.264 97.4549 268.698Z"
      stroke="#FD8FFF"
      strokeWidth="3.30011"
    />
  </svg>
)

export default TalismanHand
