const moonbeamStatement = `By accessing the Moonbeam Crowdloan Application ("Moonbeam Crowdloan Application"),  you represent and warrant to the Moonbeam Foundation LTD. ("Moonbeam Foundation") as follows:

1. you are not a citizen or resident of a country the laws of which prohibit or conflict with the holding, sale, or trading of tokens; such countries to include (without limitation) THE UNITED STATES OF AMERICA, ITS TERRITORIES AND POSSESSIONS, ANY STATE OF THE UNITED STATES, AND THE DISTRICT OF COLUMBIA ("U.S."), CANADA, PEOPLE'S REPUBLIC OF CHINA, DEMOCRATIC PEOPLE'S REPUBLIC OF KOREA, CUBA, SYRIA, IRAN, SUDAN, PEOPLE'S REPUBLIC OF CRIMEA;
2. you agree and acknowledge that nothing in the Moonbeam Crowdloan Application constitutes a prospectus or offer document of any sort nor is intended to constitute an offer of securities of any form, units in a business trust, units in a collective investment scheme, or any other form of capital markets product or investment in any jurisdiction, nor a solicitation for any form of investment;
3. you agree and acknowledge that no regulatory authority has examined or approved the information set out in the Moonbeam Crowdloan Application and the publication, distribution, or dissemination of information under the Moonbeam Crowdloan Application does not imply to you that the applicable laws, regulatory requirements, or rules have been complied with;
4. your access to, or use of, the Moonbeam Crowdloan Application and the holding of GLMR tokens by you is not prohibited or restricted by any applicable laws, regulations, or rules in any jurisdiction to which you are subject, and where any restrictions are applicable, you have observed and complied with all such restrictions at your own expense and without liability to the Moonbeam Foundation;
5. you agree and acknowledge that the Moonbeam Foundation shall not be liable for any direct, indirect, special, incidental, consequential, or other losses of any kind (including but not limited to loss of revenue, income or profits, and loss of use or data), in tort (including negligence), contract or otherwise, arising out of or in connection with you accessing or using the Moonbeam Crowdloan Application;
6. you waive the right to participate in a class-action lawsuit or a class-wide arbitration against the Moonbeam Foundation, any person involved in the Moonbeam Crowdloan Application and/or with the creation and distribution of the GLMR tokens;
7. you are not a U.S. Person as defined in Regulation S under the Securities Act of 1933, as amended, which means that you are not a natural person resident in the United States of America, its territories and possessions, any State of the United States, and the District Of Columbia ("U.S."), an entity incorporated under the laws of the U.S., an estate/trust where the executor/administrator/trustee is a U.S. Person or a non-discretionary account held for a U.S. Person, an agency or branch of a foreign entity located in the U.S., or an entity incorporated outside the U.S. but formed by a U.S. Person principally for the purposes of investing in unregistered securities under the Securities Act (unless incorporated and owned by accredited investors who are not natural persons, estates or trusts), and you acknowledge, agree and represent as follows:
   - any offer, sale, and trade of the GLMR tokens is being made in an offshore transaction, which means that the transaction was not effected in the U.S.;
   - no directed selling efforts were made in the United States, which means that no marketing efforts were made to you in the U.S.;
   - you are not acquiring GLMR tokens for the account or benefit of any U.S. Person; and
   - you agree not to offer or sell the GLMR tokens (or create or maintain any derivative position equivalent thereto) in the U.S., to or for the account or benefit of a U.S. Person;
8. you have sufficient funds to fulfill the obligations of the Moonbeam Foundation within the Moonbeam Crowdloan Application and are not bankrupt or insolvent;
9. you are acquiring GLMR tokens as principal and for your own benefit and you are not acting on the instructions of, or as nominee or agent for or on behalf of, any other person;
10. the GLMR tokens to be delivered to and received by you will not be used for any purpose in connection with money laundering, terrorism financing, or any other acts in breach or contravention of any applicable law, regulation, or rule;
11. you bear the sole responsibility to determine what tax implications your use of the Moonbeam Crowdloan Application may have for you; and
12. all of the above representations and warranties are and will continue to be, true, complete, accurate, and non-misleading from the time of your acceptance of this attestation and notwithstanding the receipt by you of any GLMR tokens.`

export default moonbeamStatement
